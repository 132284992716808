<template>
  <div class="pending_table">
    <div v-if="items">
      <b-table
        :small="small"
        hover
        striped
        responsive
        :bordered="$mq !== 'xs'"
        :stacked="$mq == 'xs'"
        head-variant="dark"
        :items="table_items"
        :fields="fields"
      >
        <template v-slot:cell(index)="row">{{ row.index + 1 }}</template>
        <template v-slot:cell(remove)="row">
          <b-button-toolbar class="table_actions" :class="$mq == 'xs' ? 'float-right' : 'float-left'">
            <b-button-group size="sm">
              <b-button @click.stop="removeData(row.item, row.index)" :disabled="!isRemovable(row.item)" variant="danger">
                <i class="fa fa-times"></i>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>

        <template v-for="property in properties" :slot="'cell(' + property['property_key'] + ')'" slot-scope="row">
          <div :key="property['property_key']" class="form_input_container">
            <div class="hidden_copyable_text">{{ relations[row.index][property['property_key']] }}</div>
            <FormInput
              :propertyType="property['type']"
              :number_config="{
                scale: _.get(property, ['rules', 'default', 'scale'], 1),
                min: _.get(property, ['rules', 'default', 'min']),
                max: _.get(property, ['rules', 'default', 'max']),
              }"
              :extParam.sync="relations[row.index][property['property_key']]"
              :prefix="property['prefix']"
              :suffix="property['suffix']"
              :disabled="!enableRemove || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true"
              :show_label="false"
              size="sm"
            ></FormInput>
          </div>
        </template>

        <template
          v-for="property in relationship ? relationship['properties'] : []"
          :slot="'cell(' + relation_alias + '_' + property['property_key'] + ')'"
          slot-scope="row"
        >
          <div :key="property['property_key']" class="form_input_container">
            <div class="hidden_copyable_text">{{ items[row.index][relationship['relationship_key']][property['property_key']] }}</div>
            <FormInput
              :propertyType="property['type']"
              :number_config="{
                scale: _.get(property, ['rules', 'default', 'scale'], 1),
                min: _.get(property, ['rules', 'default', 'min']),
                max: _.get(property, ['rules', 'default', 'max']),
              }"
              :extParam.sync="items[row.index][relationship['relationship_key']][property['property_key']]"
              :prefix="property['prefix']"
              :suffix="property['suffix']"
              :disabled="!enableRemove || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true"
              :show_label="false"
              size="sm"
            ></FormInput>
          </div>
        </template>

        <template
          v-for="field in fields.filter(field => (field.property ? field.property['type'] == 'base64' : false))"
          :slot="'cell(' + field.key + ')'"
          slot-scope="row"
        >
          <div :key="field.key">
            <div v-if="field.property['type'] == 'base64'">
              <div class="hidden_copyable_text">{{ row.value }}</div>
              <img :src="row.value" alt class="preview-image" />
            </div>
          </div>
        </template>
      </b-table>
      <b-row v-if="enableRemove">
        <b-col>
          <b-button-group size="sm">
            <b-button variant="danger" :disabled="!has_removable || !items || items.length == 0" @click.stop="removeAllData()">Remove All</b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { Switch as cSwitch } from '@coreui/vue';
import FormInput from './FormInput';

export default {
  components: {
    cSwitch,
    FormInput,
  },
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    relationship: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
    },
    relations: {
      type: Array,
    },
    enableRemove: {
      type: Boolean,
      default: false,
    },
    fixed_params: {
      type: Object,
    },
    _fields: {
      type: Array,
    },
    _exclude_fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    properties: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _property_cell_variants: {
      type: Array,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    relation_alias() {
      if (this.relationship) {
        return this.$d.getRelationAlias(this.relationship);
      }

      return null;
    },
    items: {
      // getter
      get: function() {
        if (!_.isNil(this.relations)) {
          return this.relations;
        }
        if (!_.isNil(this.params)) {
          return this.params[this.relation_alias];
        }

        return null;
      },
      // setter
      set: function(newValue) {
        if (!_.isNil(this.relations)) {
          // this.relations = newValue;
          this.$emit('update:relations', newValue);
        }
        if (!_.isNil(this.params)) {
          this.params[this.relation_alias] = newValue;
        }
      },
    },
    updatedFields() {
      return this.fields;
    },
    table_items() {
      //TODO: same as in Search.vue. PedningTable.vue & RecursiveView.vue
      return _.map(this.items, datum => {
        this.updatedFields.forEach(field => {
          let property_key = _.get(field.property, ['property_key']);
          if (field.cellVariantFunc) {
            if (!datum['_cellVariants']) {
              datum['_cellVariants'] = {};
            }
            let field_key = field['key'];
            datum['_cellVariants'][field_key] = field.cellVariantFunc(datum);
          }
        });

        if (this._property_cell_variants) {
          if (!datum['_cellVariants']) {
            datum['_cellVariants'] = {};
          }

          this._property_cell_variants.forEach(property_cell_variant => {
            let field = _.find(this.updatedFields, field => _.get(field.property, ['property_key']) == property_cell_variant['property_key']);

            if (field) {
              let field_key = field['key'];
              // console.log('property_cell_variant', field_key);
              datum['_cellVariants'][field_key] = property_cell_variant.cellVariantFunc(datum);
            } else {
              // console.warn('Cannot find field for cell variant:', property_cell_variant);
            }
          });
        }

        // datum['_rowVariant'] = 'danger';
        return datum;
      });
    },
    exclude_fields() {
      return this._exclude_fields;
      /* return [
        {
          key: 'select',
        },
      ]; */
    },
    fields() {
      let fields = [];
      if (this.relationship) {
        fields = fields.concat(this.$d.getRelationshipBTableFields(this.relationship));
        _.map(fields, field => {
          field.tdClass = ['no_break'];
          return field;
        });
        // console.log(fields);
        fields = fields.concat(this.$d.getClassBTableFields(this.relationship['to']['class_name']));
        // console.log(fields);
      }

      if (this._fields) {
        fields = fields.concat(this._fields);
      }

      //TODO: repeated across Search.vue, PendingTable.vue & SelectingSearch.vue
      fields = _.map(fields, field => {
        if (field.property) {
          let property = field.property;
          if (property['type'] == 'number') {
            if (typeof field.tdClass === 'string') {
              field.tdClass = [field.tdClass]; //convert to Array
            }
            if (!Array.isArray(field.tdClass)) {
              field.tdClass = []; //make sure it is array
            }

            field.tdClass.push('text-right');
          }
        }
        return field;
      });

      // if (this.enableRemove) {
      // fields = [{ label: '', key: 'remove' }, { label: 'No.', key: 'index' }].concat(fields);
      if (this.enableRemove) {
        fields = [{ label: '', key: 'remove', tdClass: ['no_break'] }].concat(fields);
      }

      this.exclude_fields.forEach(exclude_field => {
        fields = _.reject(fields, exclude_field);
      });

      // }
      return fields;
    },
    has_removable() {
      let removable_relation = _.find(this.items, relation => {
        return this.isRemovable(relation);
      });

      return removable_relation ? true : false;
    },
  },
  watch: {},
  mounted() {},
  created() {
    this._ = _;
  },
  methods: {
    removeData(item, index) {
      if (item.id) {
        this.items = _.filter(this.items, relation => {
          return relation.id != item.id;
        });
        // this.$emit('update:params', this.params);
        this.$emit('removed', item);
      } else {
        this.items.splice(index, 1);
        this.$emit('removed', item);
      }
    },
    removeAllData() {
      this.items = _.filter(this.items, relation => {
        return !this.isRemovable(relation);
      });
      // console.log(this.params, );
      // this.$emit('update:params', this.params);
      // this.$set(this.params, this.relation_alias, []);
      // this.$forceUpdate();
    },
    isRemovable(item) {
      // console.log('isRemovable', this.fixed_params[this.relation_alias], item);
      let found = false;

      if (this.fixed_params) {
        found = _.find(this.fixed_params[this.relation_alias], fixed_item => {
          return fixed_item['id'] == item['id'];
        });
      }
      return found ? false : true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/previewImage';

/* .card-body  thead > tr > th,
.card-body  tbody > tr > td {
  white-space: nowrap !important;
} */
.pending_table  {
  .table.table-hover {
    cursor: default; //not clickable in pending table
  }

  tbody > tr > td {
    white-space: pre-wrap;
    @media (min-width: 576px) {
      white-space: pre;
    }

    &.no_break {
      white-space: nowrap !important;
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .table, td.no_horizontal_padding {
    padding-left: 0;
    padding-right: 0;
  }

  .table, td.no_padding {
    padding: 0;
  }

  .table-sm .table_actions {
    .btn-sm,
    .btn-group-sm > .btn {
      padding: 0.1rem 0.5rem;
    }
  }

  .form_input_container {
    // width: 120px;
    min-width: 120px;
  }

  .hidden_copyable_text {
    //ref: https://stackoverflow.com/a/37927056/3553367
    color: rgba(0, 0, 0, 0); //transparent text
    font-size: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}
</style>
