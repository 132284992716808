var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pending_table"},[(_vm.items)?_c('div',[_c('b-table',{attrs:{"small":_vm.small,"hover":"","striped":"","responsive":"","bordered":_vm.$mq !== 'xs',"stacked":_vm.$mq == 'xs',"head-variant":"dark","items":_vm.table_items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(index)",fn:function(row){return [_vm._v(_vm._s(row.index + 1))]}},{key:"cell(remove)",fn:function(row){return [_c('b-button-toolbar',{staticClass:"table_actions",class:_vm.$mq == 'xs' ? 'float-right' : 'float-left'},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{attrs:{"disabled":!_vm.isRemovable(row.item),"variant":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.removeData(row.item, row.index)}}},[_c('i',{staticClass:"fa fa-times"})])],1)],1)]}},_vm._l((_vm.properties),function(property){return {key:'cell(' + property['property_key'] + ')',fn:function(row){return [_c('div',{key:property['property_key'],staticClass:"form_input_container"},[_c('div',{staticClass:"hidden_copyable_text"},[_vm._v(_vm._s(_vm.relations[row.index][property['property_key']]))]),_c('FormInput',{attrs:{"propertyType":property['type'],"number_config":{
              scale: _vm._.get(property, ['rules', 'default', 'scale'], 1),
              min: _vm._.get(property, ['rules', 'default', 'min']),
              max: _vm._.get(property, ['rules', 'default', 'max']),
            },"extParam":_vm.relations[row.index][property['property_key']],"prefix":property['prefix'],"suffix":property['suffix'],"disabled":!_vm.enableRemove || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true,"show_label":false,"size":"sm"},on:{"update:extParam":function($event){return _vm.$set(_vm.relations[row.index], property['property_key'], $event)},"update:ext-param":function($event){return _vm.$set(_vm.relations[row.index], property['property_key'], $event)}}})],1)]}}}),_vm._l((_vm.relationship ? _vm.relationship['properties'] : []),function(property){return {key:'cell(' + _vm.relation_alias + '_' + property['property_key'] + ')',fn:function(row){return [_c('div',{key:property['property_key'],staticClass:"form_input_container"},[_c('div',{staticClass:"hidden_copyable_text"},[_vm._v(_vm._s(_vm.items[row.index][_vm.relationship['relationship_key']][property['property_key']]))]),_c('FormInput',{attrs:{"propertyType":property['type'],"number_config":{
              scale: _vm._.get(property, ['rules', 'default', 'scale'], 1),
              min: _vm._.get(property, ['rules', 'default', 'min']),
              max: _vm._.get(property, ['rules', 'default', 'max']),
            },"extParam":_vm.items[row.index][_vm.relationship['relationship_key']][property['property_key']],"prefix":property['prefix'],"suffix":property['suffix'],"disabled":!_vm.enableRemove || property['type'] == 'id' || property['external_write'] == false || property['frontend_disabled'] == true || property['virtual_property'] == true,"show_label":false,"size":"sm"},on:{"update:extParam":function($event){return _vm.$set(_vm.items[row.index][_vm.relationship['relationship_key']], property['property_key'], $event)},"update:ext-param":function($event){return _vm.$set(_vm.items[row.index][_vm.relationship['relationship_key']], property['property_key'], $event)}}})],1)]}}}),_vm._l((_vm.fields.filter(field => (field.property ? field.property['type'] == 'base64' : false))),function(field){return {key:'cell(' + field.key + ')',fn:function(row){return [_c('div',{key:field.key},[(field.property['type'] == 'base64')?_c('div',[_c('div',{staticClass:"hidden_copyable_text"},[_vm._v(_vm._s(row.value))]),_c('img',{staticClass:"preview-image",attrs:{"src":row.value,"alt":""}})]):_vm._e()])]}}})],null,true)}),(_vm.enableRemove)?_c('b-row',[_c('b-col',[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{attrs:{"variant":"danger","disabled":!_vm.has_removable || !_vm.items || _vm.items.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.removeAllData()}}},[_vm._v("Remove All")])],1)],1)],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }